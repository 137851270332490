import React, { ReactNode } from 'react';
import { Box, css, IconButton, styled } from '@mui/material';

import { ReactComponent as CloseIcon } from '../../assets/icons/x-black.svg';

const Root = styled(Box)(
  ({ theme }) => css`
    position: relative;
    padding: ${theme.spacing(2)};
    background: ${theme.palette.common.white};
    border: none;
    box-shadow: none;
  }
  `,
);

const CloseButton = styled(IconButton)(
  () => css`
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 0px;
  }
  `,
);
type PopupPaperProps = { children: ReactNode; onClose?: () => void };

export const PopupContent = ({ children, onClose }: PopupPaperProps) => (
  <Root>
    {onClose && (
      <CloseButton aria-label="Schließen" onClick={onClose}>
        <CloseIcon />
      </CloseButton>
    )}

    {children}
  </Root>
);
