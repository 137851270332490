// import dayjs from 'dayjs';
import React from 'react';
import { Layer, Source } from 'react-map-gl';
import { useQuery } from '@tanstack/react-query';

import { DefaultService } from '../../services/api';
import { FeatureLayerType } from '../../types/FeatureLayerType';
import { TrafficReportType } from '../../types/TrafficReportType';

export const TrafficReportLayer = () => {
  const { data, status } = useQuery(['location_traffic_events'], () =>
    DefaultService.getLocationTrafficEventsLocationTrafficEventsGet(),
  );

  return (
    <>
      {!data && status === 'loading' && 'Loading...'}
      {!data && status === 'error' && 'Error'}

      {data && (
        <Source type="geojson" id={FeatureLayerType.TRAFFIC_REPORT} data={data}>
          <Layer
            id={FeatureLayerType.TRAFFIC_REPORT}
            filter={['==', '$type', 'Point']}
            type="symbol"
            layout={{
              'icon-size': 0.5,
              'icon-image': [
                'match',
                ['get', 'situation_record_type'],
                'd2:ConstructionWorks',
                TrafficReportType.CONSTRUCTION_WORKS,
                'd2:AbnormalTraffic',
                TrafficReportType.ABNORMAL_TRAFFIC,
                'd2:Accident',
                TrafficReportType.ACCIDENT,
                'd2:MaintenanceWorks',
                TrafficReportType.MAINTENANCE_WORKS,
                'd2:ReroutingManagement',
                TrafficReportType.REROUTING_MANAGEMENT,
                'd2:Conditions',
                TrafficReportType.ENVIRONMENTAL_OBSTRUCTION,
                'd2:WinterDrivingManagement',
                TrafficReportType.ENVIRONMENTAL_OBSTRUCTION,
                'd2:PublicEvent',
                TrafficReportType.PUBLIC_EVENT,
                TrafficReportType.WARNING,
              ],
            }}
          />
          <Layer
            id={`${FeatureLayerType.TRAFFIC_REPORT}line`}
            filter={['==', '$type', 'LineString']}
            type="line"
            layout={{
              'line-cap': 'round',
              'line-join': 'round',
            }}
            paint={{
              'line-color': '#cf2e26',
              'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 2, 14, 4, 18, 8],
              'line-dasharray': [1, 2],
            }}
          />
        </Source>
      )}
    </>
  );
};
