import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { MainPage } from './pages/MainPage';
import { theme } from './setup/theme';
import { AppProps } from './types/AppStaticType';

const queryClient = new QueryClient();

export const App = (props: AppProps) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <QueryClientProvider client={queryClient}>
      <MainPage {...props} />
    </QueryClientProvider>
  </ThemeProvider>
);
