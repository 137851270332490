import React from 'react';
import { Box, styled, css } from '@mui/material';

import { ResultMap } from '../components/Map';
import { KPIGrid } from '../components/kpi/KPIGrid';
import { Nudges } from '../components/Nudges';
import { Monitor } from '../components/Monitor';
import { SearchAppBar } from '../components/Appbar';
import { AppProps } from '../types/AppStaticType';

const Root = styled(Box)(
  ({ theme }) => css`
    padding-top: ${theme.spacing(7)};

    ${theme.breakpoints.up('lg')} {
      flex: 1 0 auto;
      display: flex;
      width: 100%;
      height: calc(100vh);
    }
  `,
);

const Item = styled(Box)(
  ({ theme }) =>
    css`
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: ${theme.spacing(1)};

      ${theme.breakpoints.up('lg')} {
        width: 60%;
      }
    `,
);

const MapWrapper = styled(Box)(
  ({ theme }) =>
    css`
      height: 75vh;
      width: 100%;

      ${theme.breakpoints.up('lg')} {
        position: fixed;
        top: ${theme.spacing(7)};
        right: 0;
        width: 40%;
        height: calc(100vh - ${theme.spacing(7)});
      }
    `,
);

const ItemContent = styled(Box)(
  ({ theme }) =>
    css`
      width: 100%;
      padding: ${theme.spacing(2)};
      display: flex;
      flex-direction: column;
      padding: ${theme.spacing(1)};
    `,
);

export const MainPage = (props: AppProps) => (
  <>
    <SearchAppBar />
    <Root sx={{ minWidth: '296px', overflow: 'auto' }}>
      <Item>
        <ItemContent>
          <KPIGrid {...props} />
        </ItemContent>
        <ItemContent>
          <Nudges />
        </ItemContent>
        <ItemContent>
          <Monitor />
        </ItemContent>
      </Item>

      <MapWrapper>
        <ResultMap {...props} />
      </MapWrapper>
    </Root>
  </>
);
