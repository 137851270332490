export enum TrafficReportType {
  ABNORMAL_TRAFFIC = 'AbnormalTraffic',
  ACCIDENT = 'Accident',
  CONDITIONS = 'Conditions',
  CONSTRUCTION_WORKS = 'ConstructionWorks',
  ENVIRONMENTAL_OBSTRUCTION = 'EnvironmentalObstruction',
  MAINTENANCE_WORKS = 'MaintenanceWorks',
  PUBLIC_EVENT = 'PublicEvent',
  REROUTING_MANAGEMENT = 'ReroutingManagement',
  WARNING = 'Warning',
}
