import { BackgroundLayerType } from '../types/BackgroundLayerType';
import { TrafficReportType } from '../types/TrafficReportType';
import { FeatureLayerType } from '../types/FeatureLayerType';
import { PlottingVariables } from '../types/PlottingVariables';

export const de = {
  BackgroundLayerType: {
    [BackgroundLayerType.BASE_MAP]: 'Basemap',
    [BackgroundLayerType.DARK_MAP]: 'Dark',
    [BackgroundLayerType.LIGHT_MAP]: 'Light',
    [BackgroundLayerType.SATELLITE_MAP]: 'Satellite',
  },

  FeatureLayerType: {
    [FeatureLayerType.TRAFFIC_REPORT]: 'Traffic News',
    [FeatureLayerType.BIKEABILITY]: 'Bikeability',
    [FeatureLayerType.WALKABILITY]: 'Walkability',
    [FeatureLayerType.VERKEHRSLAGE]: 'Level of Service',
  },

  TrafficReportType: {
    [TrafficReportType.ABNORMAL_TRAFFIC]: 'Congestion',
    [TrafficReportType.ACCIDENT]: 'Accident',
    [TrafficReportType.CONDITIONS]: 'Environmental alert',
    [TrafficReportType.CONSTRUCTION_WORKS]: 'Construction work',
    [TrafficReportType.ENVIRONMENTAL_OBSTRUCTION]: 'Natural event',
    [TrafficReportType.MAINTENANCE_WORKS]: 'Maintenance work',
    [TrafficReportType.PUBLIC_EVENT]: 'Event',
    [TrafficReportType.REROUTING_MANAGEMENT]: 'Detour',
    misc: 'Miscellaneous ({{type}})',
  },

  PlottingVariables: {
    [PlottingVariables.REGEN]: 'Rain',
    [PlottingVariables.SONNENSCHEIN]: 'Sunshine duration in min.',
    [PlottingVariables.TEMPERATUR]: 'Temperature in °C',
    [PlottingVariables.WINDGESCHWINDIGKEIT]: 'Wind speed in m/s',
  },
};
