/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {
  /**
   * Root
   * @returns any Successful Response
   * @throws ApiError
   */
  public static rootGet(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/',
    });
  }

  /**
   * Get Kpis
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getKpisKpisGet(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/kpis',
    });
  }

  /**
   * Get Departures
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getDeparturesDeparturesGet({ long, lat }: { long?: number; lat?: number }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/departures',
      query: {
        long: long,
        lat: lat,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Location Traffic Events
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getLocationTrafficEventsLocationTrafficEventsGet(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/location_traffic_events',
    });
  }

  /**
   * Get Traffic Flow State
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getTrafficFlowStateTrafficFlowStateGet(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/traffic_flow_state',
    });
  }

  /**
   * Get Walkability Raster
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getWalkabilityRasterWalkabilityRasterGet(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/walkability_raster',
    });
  }

  /**
   * Get Bikeability Raster
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getBikeabilityRasterBikeabilityRasterGet(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/bikeability_raster',
    });
  }

  /**
   * Get Weather
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getWeatherWeatherGet(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/weather',
    });
  }

  /**
   * Sensorbox
   * @returns any Successful Response
   * @throws ApiError
   */
  public static sensorboxSensorboxPost({ requestBody }: { requestBody: Record<string, any> }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/sensorbox',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Points
   * @returns any Successful Response
   * @throws ApiError
   */
  public static pointsPointsPost({ requestBody }: { requestBody: Record<string, any> }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/points',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Notifications
   * @returns any Successful Response
   * @throws ApiError
   */
  public static notificationsNotificationsPost({
    requestBody,
  }: {
    requestBody: Record<string, any>;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/notifications',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
