import AbnormalTraffic from '../../assets/icons/traffic-report-type/abnormal-traffic.png';
import Accident from '../../assets/icons/traffic-report-type/accident.png';
import Conditions from '../../assets/icons/traffic-report-type/conditions.png';
import ConstructionWorks from '../../assets/icons/traffic-report-type/construction-works.png';
import EnvironmentalObstruction from '../../assets/icons/traffic-report-type/environmental-obstruction.png';
import MaintenanceWorks from '../../assets/icons/traffic-report-type/maintenance-works.png';
import PublicEvent from '../../assets/icons/traffic-report-type/public-event.png';
import ReroutingManagement from '../../assets/icons/traffic-report-type/rerouting-management.png';
import Warning from '../../assets/icons/traffic-report-type/warning.png';
import { TrafficReportType } from '../../types/TrafficReportType';

export class TrafficReportIcon {
  public static getImage(type: TrafficReportType) {
    return {
      [TrafficReportType.ABNORMAL_TRAFFIC]: AbnormalTraffic,
      [TrafficReportType.ACCIDENT]: Accident,
      [TrafficReportType.CONDITIONS]: Conditions,
      [TrafficReportType.CONSTRUCTION_WORKS]: ConstructionWorks,
      [TrafficReportType.ENVIRONMENTAL_OBSTRUCTION]: EnvironmentalObstruction,
      [TrafficReportType.MAINTENANCE_WORKS]: MaintenanceWorks,
      [TrafficReportType.PUBLIC_EVENT]: PublicEvent,
      [TrafficReportType.REROUTING_MANAGEMENT]: ReroutingManagement,
      [TrafficReportType.WARNING]: Warning,
    }[type];
  }
}
