import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';
import { deDE } from '@mui/material/locale';
import { css } from '@emotion/react';
import { SimplePaletteColorOptions } from '@mui/material';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    card: {
      header: SimplePaletteColorOptions;
    };
  }

  interface PaletteOptions {
    card: {
      header: SimplePaletteColorOptions;
    };
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true; // adds the `mobile` breakpoint
    special: true;
  }
}

let themeBuilder = createTheme(
  {
    palette: {
      info: {
        main: '#e2e2e2',
      },

      card: {
        header: { main: '#f65a5a', contrastText: 'white' },
      },
    },

    shadows: [
      'none',
      '0px 3px 12px rgba(0, 0, 0, 0.16)',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
    breakpoints: {
      values: {
        xs: 0,
        sm: 641,
        md: 900,
        lg: 1200,
        xl: 1602,
        mobile: 336, // adds the `mobile` breakpoint
        special: 1070,
      },
    },
  },
  deDE,
);

themeBuilder = createTheme(themeBuilder, {
  components: {
    MuiCssBaseline: {
      styleOverrides: () =>
        css`
          body *:focus-visible {
            outline: 2px solid ${themeBuilder.palette.primary.main};
          }
        `,
    },

    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
} as ThemeOptions);

themeBuilder = responsiveFontSizes(themeBuilder);

export const theme = themeBuilder;
