import { Box, Card, CardContent, CardHeader, css, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import ArrowUp from '@mui/icons-material/ArrowUpward';
import ArrowDown from '@mui/icons-material/ArrowDownward';

import { WeatherBox } from './WeatherBox';

const Value = styled(Box)(
  css`
    line-height: 1.1;
    font-size: 1.5rem;
    font-weight: bold;
    padding-left: 10px;
  `,
);

const Text = styled(Typography)(
  ({ theme }) => css`
    font-size: 1rem;
    line-height: 1;
    color: ${theme.palette.text.secondary};
    vertical-align: 'middle';
    padding-right: 4px;
  `,
);

const StyledCardContent = styled(CardContent)(
  ({ theme }) => css`
    padding: ${theme.spacing(1)} !important;
  `,
);

type CardProps = {
  header?: string;
  icon?: any;
  subheader?: string;
  value?: string;
  text?: string;
  weatherdata?: boolean;
  isStatic: boolean;
};

type ArrowProps = {
  text?: string;
  header?: string;
};
const Arrow = ({ text, header }: ArrowProps) => {
  if (!text) return null;
  const textstr = String(text);

  if (header === 'Bicycle traffic' || header === 'Pedestrian traffic') {
    if (textstr.includes('mehr') || textstr.includes('more')) {
      return <ArrowUp color="success" sx={{ verticalAlign: 'sub' }} />;
    }

    return <ArrowDown color="error" sx={{ verticalAlign: 'sub' }} />;
  }
  if (textstr.includes('weniger') || textstr.includes('less')) {
    return <ArrowDown color="success" sx={{ verticalAlign: 'sub' }} />;
  }

  return <ArrowUp color="error" sx={{ verticalAlign: 'sub' }} />;
};

export const KPICard = ({ header, icon, subheader, value, text, weatherdata, isStatic }: CardProps) => (
  <Card sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
    {!header && <Box sx={{ textAlign: 'center', margin: 'auto' }}>{icon}</Box>}
    {header && (
      <CardHeader
        sx={{ padding: '12px' }}
        avatar={icon}
        title={
          <Typography variant="h6" fontWeight="bold">
            {header}
          </Typography>
        }
        subheader={subheader}
      />
    )}

    {weatherdata && <WeatherBox isStatic={isStatic} />}
    {header && (
      <StyledCardContent>
        <Stack direction="row" spacing={5} alignItems="center" justifyContent="space-between">
          <Value>{value}</Value>
          <Text>
            <>
              <Arrow text={text} header={header} />
              {text}
            </>
          </Text>
        </Stack>
      </StyledCardContent>
    )}
  </Card>
);
