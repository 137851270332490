import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Link, styled, css, IconButton, Modal, Divider } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';

import DymonLogo from '../assets/logos/DymonLogo.jpg';
import { ReactComponent as TrafficonLogo } from '../assets/logos/Trafficon-Logo.svg';
import { ReactComponent as EUIcon } from '../assets/logos/European_Commission.svg';
import { ReactComponent as SalzburgResearch } from '../assets/logos/Salzburg_Research_logo.svg';
import { ReactComponent as UrbanEuropeIcon } from '../assets/logos/JPI-Urban-Europe-logo.svg';
import ZgisIcon from '../assets/logos/zgis.png';
import { ImpressumModal } from './Impressum';

const FlexBox = styled(Box)(
  ({ theme }) => css`
    display: flex;

    ${theme.breakpoints.down('special')} {
      display: none;
    }
  `,
);
const HiddenBox = styled(Box)(
  ({ theme }) => css`
    display: none;

    ${theme.breakpoints.down('special')} {
      display: block;
    }
  `,
);
const StyledBox = styled(Box)(
  ({ theme }) => css`
    text-align: center;
    display: block;

    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(0)};
    }
    ${theme.breakpoints.down('special')} {
      padding: ${theme.spacing(2)};
    }
  `,
);
const PartnerBox = styled(Box)(
  ({ theme }) => css`
    background-color: ${theme.palette.common.white};
    padding: 16px;
    display: block;
    width: 80%;
    margin: 0 auto;
    top: 7vh;
    position: relative;
    max-height: 85%;
    overflow: auto;
    padding: ${theme.spacing(2)};

    ${theme.breakpoints.up('lg')} {
      width: 60%;
    }
  `,
);
const StyledLinkDesc = styled(Typography)(
  ({ theme }) => css`
    display: none;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 550;

    ${theme.breakpoints.down('special')} {
      display: block;
    }
  `,
);

const LogoBanner = () => (
  <>
    <StyledLinkDesc variant="h5" sx={{ marginTop: '10px', textAlign: 'center' }}>
      Partner:
      <Divider flexItem light />
    </StyledLinkDesc>

    <StyledBox>
      <Link href="https://www.salzburgresearch.at/" target="_blank">
        <SalzburgResearch aria-hidden />
      </Link>
      <StyledLinkDesc variant="body1">Salzburg Research Forschungsgesellschaft mbH</StyledLinkDesc>
    </StyledBox>

    <StyledBox>
      <Link href="https://www.trafficon.eu/" target="_blank">
        <TrafficonLogo />
      </Link>
      <StyledLinkDesc>Trafficon GmbH</StyledLinkDesc>
    </StyledBox>

    <StyledBox>
      <Link href="https://jpi-urbaneurope.eu/" target="_blank">
        <UrbanEuropeIcon aria-hidden />
      </Link>
      <StyledLinkDesc>JPI Urban Europe</StyledLinkDesc>
    </StyledBox>

    <StyledBox sx={{ marginTop: '5px' }}>
      <Link href="https://www.plus.ac.at/geoinformatik/" target="_blank">
        <img src={ZgisIcon} height="40px" alt="Urban Europe Logo" />
      </Link>
      <StyledLinkDesc variant="body1">Paris Lodron Universität Salzburg</StyledLinkDesc>
      <StyledLinkDesc variant="subtitle2">Department of Geoinformatics – Z_GIS</StyledLinkDesc>
    </StyledBox>

    <StyledLinkDesc variant="h5" sx={{ marginTop: '30px', textAlign: 'center' }}>
      Funded by:
      <Divider flexItem light variant="middle" />
    </StyledLinkDesc>
    <StyledBox>
      <Link href="https://commission.europa.eu/index_de" target="_blank">
        <EUIcon aria-hidden />
      </Link>
      <StyledLinkDesc variant="body1">European Commission</StyledLinkDesc>
    </StyledBox>
  </>
);

export const SearchAppBar = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [dateState, setDateState] = useState(new Date());
  useEffect(() => {
    setInterval(() => setDateState(new Date()), 1000);
  }, []);

  return (
    <AppBar
      position="fixed"
      enableColorOnDark
      sx={{ backgroundColor: 'white', color: 'black', borderBottom: '1px solid #1976d2', height: 60 }}
    >
      <Toolbar sx={{ minHeight: '60px !important' }}>
        <Link href="https://dymon.eu/" target="_blank" sx={{ paddingRight: '30px' }}>
          <img src={DymonLogo} height="40px" alt="Dymon Logo" />
        </Link>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
          {dateState.toLocaleString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            hour12: false,
            minute: '2-digit',
            second: '2-digit',
          })}
        </Typography>
        <ImpressumModal />
        <HiddenBox>
          <IconButton
            onClick={handleOpen}
            size="small"
            sx={{ padding: '10px', borderRadius: '4px', color: 'rgba(0, 0, 0, 0.87)' }}
          >
            <PeopleIcon color="action" />
            <Typography noWrap sx={{ flexGrow: 1, fontSize: { mobile: '12px', xs: '0px', sm: '15px' } }}>
              Partner
            </Typography>
          </IconButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <PartnerBox>{LogoBanner()}</PartnerBox>
          </Modal>
        </HiddenBox>
        <FlexBox>{LogoBanner()}</FlexBox>
      </Toolbar>
    </AppBar>
  );
};
