// import mapboxgl from 'mapbox-gl';
import { BackgroundLayerType } from '../types/BackgroundLayerType';

export class MapBackground {
  public static id = {
    [BackgroundLayerType.BASE_MAP]: {
      name: 'Basemap',
      styleData: '/basemapv-bmapv-3857-resources-styles-root.json',
    },
    [BackgroundLayerType.LIGHT_MAP]: {
      name: 'Maptiler Light',
      styleData: 'https://api.maptiler.com/maps/basic-v2-light/style.json?key=tJcxWtnU9gL1tJ9eKtnN',
    },
    [BackgroundLayerType.DARK_MAP]: {
      name: 'Maptiler Dark',
      styleData: 'https://api.maptiler.com/maps/dataviz-dark/style.json?key=tJcxWtnU9gL1tJ9eKtnN',
    },
    [BackgroundLayerType.SATELLITE_MAP]: {
      name: 'Maptiler Satelitte',
      styleData: 'https://api.maptiler.com/maps/hybrid/style.json?key=tJcxWtnU9gL1tJ9eKtnN',
    },
  };
}
