import React from 'react';
import { Layer, LayerProps } from 'react-map-gl';

import { useBeforeIdLabels } from './hooks/useBeforeIds';

type WhiteLineBorderLayerProps = { 'source-layer'?: string };

const whiteLineProps: LayerProps = {
  type: 'line',
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
  paint: {
    'line-color': 'white',
    'line-opacity': 0.5,
    'line-width': ['interpolate', ['exponential', 0.3], ['zoom'], 10, 1, 14, 5, 18, 10],
  },
};

export const WhiteLineBorderLayer = (props: WhiteLineBorderLayerProps) => {
  const { beforeIdLabels } = useBeforeIdLabels();

  return <Layer {...props} beforeId={beforeIdLabels} {...whiteLineProps} />;
};
