import React from 'react';
import { Typography } from '@mui/material';
import { Popup } from 'react-map-gl';

import { PopupContent } from './PopupContent';
import { BikeabilityFeature } from '../../types/BikeabilityFeature';
import { getCenter } from '../services/GetCenter';

type BikeabilityPopupProps = {
  feature?: BikeabilityFeature;
  onClose: () => void;
};

export const BikeabilityPopup = ({ feature, onClose }: BikeabilityPopupProps) => {
  if (!feature) {
    return null;
  }

  const [poly] = feature.geometry.coordinates;
  const { id, index } = feature.properties;
  const center = getCenter(poly);

  return (
    <Popup
      key={`${String(id)}:${index}`}
      longitude={center[0]}
      latitude={center[1]}
      onClose={onClose}
      offset={0}
      maxWidth="min(480px, 75vw)"
      closeButton={false}
    >
      <PopupContent onClose={onClose}>
        <Typography fontWeight="bold" color="text.primary" mb={1} sx={{ fontSize: '1rem' }}>
          Bikeability
        </Typography>
        <Typography color="text.primary" mb={1}>
          {index}
        </Typography>
      </PopupContent>
    </Popup>
  );
};
