import React from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl';
import { useQuery } from '@tanstack/react-query';

import { DefaultService } from '../../services/api';
import { FeatureLayerType } from '../../types/FeatureLayerType';
import { useBeforeIdLabels } from './hooks/useBeforeIds';

const walkabilityLayerProps: LayerProps = {
  id: FeatureLayerType.WALKABILITY,
  type: 'fill',
  paint: {
    'fill-color': [
      'interpolate',
      ['linear'],
      ['get', 'index'],
      0,
      'darkred',
      0.2,
      'red',
      0.4,
      'yellow',
      0.6,
      '#cbf779',
      0.8,
      'green',
      0.9,
      'darkgreen',
    ],
    'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.3],
  },
  minzoom: 0,
  maxzoom: 22,
};

export const WalkabilityLayer = () => {
  const { data, status } = useQuery(
    ['walkability_raster'],
    () => DefaultService.getWalkabilityRasterWalkabilityRasterGet(),
    { cacheTime: 86400000, staleTime: 86400000 },
  );
  const { beforeIdLabels } = useBeforeIdLabels();

  return (
    <>
      {!data && status === 'loading' && 'Loading...'}
      {!data && status === 'error' && 'Error'}

      {data && (
        <Source type="geojson" id={FeatureLayerType.WALKABILITY} data={data}>
          <Layer beforeId={beforeIdLabels} {...walkabilityLayerProps} />
        </Source>
      )}
    </>
  );
};
