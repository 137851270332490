import React, { useEffect, useRef, useState } from 'react';
import Map, {
  MapRef,
  NavigationControl,
  Popup,
  Marker,
  GeolocateControl,
  FullscreenControl,
  ScaleControl,
  Source,
  Layer,
  LayerProps,
  AttributionControl,
} from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import { Stack, styled, css, Typography } from '@mui/material';
import bbox from '@turf/bbox';

import { PinMemo } from './map/pin';
import { useMapStore } from '../stores/useMapStore';
import { MapBackground } from '../services/MapBackground';
import { LayersControl } from './controls/LayersControl';
import { PopupContent } from './map/PopupContent';
import { FeatureLayers } from './map/FeatureLayers';
import { BikeabilityPopup } from './map/BikeabilityPopup';
import { BikeabilityFeature } from '../types/BikeabilityFeature';
import { FeatureLayerType } from '../types/FeatureLayerType';
import { WalkabilityPopup } from './map/WalkabilityPopup';
import { WalkabilityFeature } from '../types/WalkabilityFeature';
import { TrafficReportFeature } from '../types/TrafficReportFeature';
import { TrafficReportPopup } from './map/TrafficReportPopup';
import { TrafficReportType } from '../types/TrafficReportType';
import { TrafficReportIcon } from './services/TrafficReportIcon';
import { MapImages } from './map/MapImages';
import { LegendControl } from './controls/LegendControl';
import { FeatureLayerLegend, FeatureLayerLegendText } from './services/MapLegend';
import { AppProps } from '../types/AppStaticType';

const ZgisMarker = [
  {
    latitude: 47.822729816071465,
    longitude: 13.040317811340785,
  },
];
const initialViewState = {
  longitude: 13.040317811340785,
  latitude: 47.822729816071465,
  zoom: 11,
  bearing: 0,
  pitch: 0,
};
const routesLayerProps: LayerProps = {
  id: 'RoutingLayer',
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#2626ff',
    'line-width': 8,
  },
};

const StyledStack = styled(Stack)(
  ({ theme }) =>
    css`
      background-color: #ffffff;
      opacity: 0.7;
      box-shadow: ${theme.shadows[1]};
      position: absolute;
      bottom: ${theme.spacing(3)};
      right: ${theme.spacing(1)};
      z-index: 1;
      border-radius: ${theme.spacing(2)};

      ${theme.breakpoints.up('xs')} {
        bottom: ${theme.spacing(5)};
      }
      ${theme.breakpoints.up('sm')} {
        bottom: ${theme.spacing(3)};
      }
      ${theme.breakpoints.up('lg')} {
        bottom: ${theme.spacing(5)};
      }
      ${theme.breakpoints.up('xl')} {
        bottom: ${theme.spacing(3)};
      }
    `,
);

export const ResultMap = ({ isStatic }: AppProps) => {
  const map = useRef<MapRef>(null);
  const layers = useMapStore((state) => state.layers);
  const setLayers = useMapStore((state) => state.actions.setLayers);
  const userLocation = useMapStore((state) => state.userLocation);
  const setUserLocation = useMapStore((state) => state.actions.setUserLocation);
  const backgroundLayer = useMapStore((state) => state.backgroundLayer);
  const highlightMonitoringRoute = useMapStore((state) => state.highlightMonitoringRoute);
  const zoomMonitoringRoute = useMapStore((state) => state.zoomMonitoringRoute);

  const [showPopup, setShowPopup] = useState(false);
  const [bikeabilityPopupFeature, setBikeabilityPopupFeature] = useState<BikeabilityFeature>();
  const [walkabilityPopupFeature, setWalkabilityPopupFeature] = useState<WalkabilityFeature>();
  const [trafficReportPopupFeature, setTrafficReportPopupFeature] = useState<TrafficReportFeature>();

  const interactiveLayerIds = [...Object.values(FeatureLayerType).filter((layer) => layers.features[layer])];

  useEffect(() => {
    if (zoomMonitoringRoute) {
      const [minLng, minLat, maxLng, maxLat] = bbox(zoomMonitoringRoute);
      map.current?.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: 20,
        },
      );
    }
  }, [zoomMonitoringRoute]);

  const [changeLayer, setChangeLayer] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isStatic) {
        setChangeLayer(!changeLayer);
        setLayers({
          features: {
            [FeatureLayerType.TRAFFIC_REPORT]: true,
            [FeatureLayerType.BIKEABILITY]: changeLayer,
            [FeatureLayerType.WALKABILITY]: !changeLayer,
            [FeatureLayerType.VERKEHRSLAGE]: true,
          },
        });
      }
    }, 30000);

    return () => clearInterval(intervalId);
  });

  return (
    <Map
      mapLib={maplibregl}
      ref={map}
      initialViewState={initialViewState}
      mapStyle={MapBackground.id[backgroundLayer].styleData}
      interactiveLayerIds={interactiveLayerIds}
      minPitch={0}
      maxPitch={85}
      // onMouseMove={(event) => {
      //   const source = event.features?.[0]?.source;
      //   if (source === 'TRAFFIC_REPORT' || source === 'BIKEABILITY' || source === 'WALKABILITY') {
      //     event.target.getCanvas().style.cursor = 'pointer';
      //   } else {
      //     event.target.getCanvas().style.removeProperty('cursor');
      //   }
      // }}
      onClick={(event) => {
        const [feature] = event.features || [];
        switch (feature?.layer.id) {
          case FeatureLayerType.TRAFFIC_REPORT:
            setTrafficReportPopupFeature(feature as unknown as TrafficReportFeature);
            break;
          case FeatureLayerType.BIKEABILITY:
            setBikeabilityPopupFeature(feature as unknown as BikeabilityFeature);
            break;
          case FeatureLayerType.WALKABILITY:
            setWalkabilityPopupFeature(feature as unknown as WalkabilityFeature);
            break;
          default:
            break;
        }
      }}
    >
      {/* Controls */}
      {!isStatic && (
        <>
          <LayersControl />
          <GeolocateControl
            position="top-left"
            onGeolocate={(event) => {
              setUserLocation({ long: event.coords.longitude, lat: event.coords.latitude });
            }}
          />
          <FullscreenControl position="top-left" />
          <ScaleControl />
          <NavigationControl />
        </>
      )}

      {/* Legends */}
      <StyledStack>
        {Object.entries(layers.features)
          .map((arr) => (arr[1] ? arr[0] : null))
          .map((type) => {
            if (!type || type === 'TRAFFIC_REPORT') {
              return null;
            }

            const legendText = Object.entries(FeatureLayerLegendText).find((entry) => entry[0] === type);
            const legend = Object.entries(FeatureLayerLegend).find((entry) => entry[0] === type);

            return (
              legendText &&
              legend && (
                <LegendControl
                  key={legendText[1].title}
                  title={legendText[1].title}
                  legend={legend[1]}
                  titleTooltip={legendText[1].tooltip}
                />
              )
            );
          })}
      </StyledStack>

      {/* Features */}
      <FeatureLayers />
      {highlightMonitoringRoute && !isStatic && (
        <Source type="geojson" id="RoutingLayer" data={highlightMonitoringRoute}>
          <Layer {...routesLayerProps} />
        </Source>
      )}
      <MapImages
        images={[
          ...Object.values(TrafficReportType).map((name) => ({
            name,
            url: TrafficReportIcon.getImage(name),
          })),
        ]}
      />

      {!isStatic && (
        <>
          <TrafficReportPopup
            feature={trafficReportPopupFeature}
            onClose={() => setTrafficReportPopupFeature(undefined)}
          />
          <BikeabilityPopup feature={bikeabilityPopupFeature} onClose={() => setBikeabilityPopupFeature(undefined)} />
          <WalkabilityPopup feature={walkabilityPopupFeature} onClose={() => setWalkabilityPopupFeature(undefined)} />
        </>
      )}
      <Marker
        key="zgis_marker"
        // longitude={ZgisMarker[0].longitude}
        // latitude={ZgisMarker[0].latitude}
        longitude={userLocation?.long}
        latitude={userLocation?.lat}
        anchor="bottom"
        onClick={(e) => {
          e.originalEvent.stopPropagation();
          setShowPopup(true);
        }}
      >
        <PinMemo />
      </Marker>
      {showPopup && !isStatic && (
        <Popup
          key="zgis_marker_popup"
          longitude={ZgisMarker[0].longitude}
          latitude={ZgisMarker[0].latitude}
          anchor="bottom"
          offset={25}
          maxWidth="min(480px, 75vw)"
          onClose={() => setShowPopup(false)}
        >
          <PopupContent>
            <Typography>Z Gis</Typography>
          </PopupContent>
        </Popup>
      )}

      {backgroundLayer === 'BASE_MAP' && (
        <AttributionControl customAttribution='<a href="https://basemap.at/" target="_blank" title="Datenquelle: basemap.at" aria-label="Datenquelle: basemap.at" role="listitem">© Datenquelle: basemap.at</a>' />
      )}
    </Map>
  );
};
