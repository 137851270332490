import React from 'react';
import { styled, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import {
  WiCloudy,
  WiDayRain,
  WiDayRainMix,
  WiDaySunny,
  WiDaySunnyOvercast,
  WiDayWindy,
  WiNightAltRain,
  WiNightAltRainMix,
  WiMoonWaningCrescent3,
} from 'react-icons/wi';

import { PlottingFeatureCollection } from './types/PlottingFeature';

const StyledTableCell = styled(TableCell)(() => ({
  padding: 1,
  margin: 0,
  textAlign: 'center',
  borderBottom: 'unset',
}));

const getWeatherIcon = (feat: any) => {
  if ([0, 1, 2, 3, 4, 5, 20, 21, 22, 23, 24].includes(new Date(feat.properties.date_time).getHours())) {
    if (feat.properties.precipitation > 0 && feat.properties.precipitation <= 10) {
      return <WiNightAltRainMix size={50} />;
    }
    if (feat.properties.precipitation > 10) {
      return <WiNightAltRain size={50} />;
    }

    return <WiMoonWaningCrescent3 size={50} />;
  }
  if (feat.properties.sunshine_duration > 50) {
    if (feat.properties.wind_speed > 5) {
      return <WiDayWindy size={50} color="#eed802" />;
    }

    return <WiDaySunny size={50} color="#eed802" />;
  }
  if (feat.properties.sunshine_duration <= 50 && feat.properties.sunshine_duration > 10) {
    if (feat.properties.precipitation > 0 && feat.properties.precipitation <= 10) {
      return <WiDayRainMix size={50} color="#78b2ff" />;
    }
    if (feat.properties.precipitation > 10) {
      return <WiDayRain size={50} color="#5555f6" />;
    }

    return <WiDaySunnyOvercast size={50} color="#eed802" />;
  }
  if (feat.properties.sunshine_duration <= 10) {
    if (feat.properties.precipitation > 0) {
      return <WiDayRainMix size={50} color="#5555f6" />;
    }

    return <WiCloudy size={50} />;
  }

  return null;
};

export const WeatherTable = ({ features }: PlottingFeatureCollection) => {
  const currentDateHour = new Date(new Date().setHours(new Date().getHours(), 0, 0, 0));
  const futureTimestamps = features.filter((x) => new Date(x.properties.date_time) >= currentDateHour).slice(0, 3);

  return (
    <TableContainer>
      <Table sx={{ display: 'flex', width: '100%' }} aria-label="simple table">
        <TableBody sx={{ display: 'flex', width: '100%' }}>
          <>
            {futureTimestamps.map((feat) => (
              <TableRow
                key={feat.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '33%',
                }}
              >
                <StyledTableCell>{getWeatherIcon(feat)}</StyledTableCell>
                {/* <StyledTableCell>{Math.round(feat.properties.wind_speed)}</StyledTableCell> */}
                {/* <StyledTableCell>{Math.round(feat.properties.wind_speed * 3.6)}</StyledTableCell> */}
                <StyledTableCell>{`${feat.properties.temperature}°C`}</StyledTableCell>
                <StyledTableCell>
                  {new Date(feat.properties.date_time).toLocaleTimeString(navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </StyledTableCell>
              </TableRow>
            ))}
          </>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
