import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Popup } from 'react-map-gl';

import { PopupContent } from './PopupContent';
import { TrafficReportFeature } from '../../types/TrafficReportFeature';

type TrafficReportPopupProps = {
  feature?: TrafficReportFeature;
  onClose: () => void;
};

export const TrafficReportPopup = ({ feature, onClose }: TrafficReportPopupProps) => {
  const { t } = useTranslation();

  if (!feature) {
    return null;
  }

  const [longitude, latitude] = feature.geometry.coordinates;
  const {
    situation_record_type: type,
    general_public_comment: description,
    overall_start_time: from,
    overall_end_time: to,
    // direction,
  } = feature.properties;

  return (
    <Popup
      key={type + description + from + to}
      longitude={longitude}
      latitude={latitude}
      onClose={onClose}
      offset={18}
      maxWidth="min(480px, 75vw)"
      closeButton={false}
    >
      <PopupContent onClose={onClose}>
        <Typography fontWeight="bold" color="text.primary" mb={1} sx={{ fontSie: '0.75rem' }}>
          {t([`TrafficReportType.${type.replace('d2:', '')}`, 'TrafficReportType.misc'], { type })}
        </Typography>

        {(to || from) && (
          <Typography mb={1}>
            {from && !to && 'Ab '}
            {from}

            {from && to && ' – '}

            {!from && to && 'Bis '}
            {to}
          </Typography>
        )}

        {/* <Typography mb={1} color="text.primary">{`Richtung: ${direction}`}</Typography> */}

        {description && (
          <Typography color="text.primary">
            {description.replace('{}', '').replace('{"', '').replace('"}', '')}
          </Typography>
        )}
      </PopupContent>
    </Popup>
  );
};
