import { Position } from 'geojson';

export const getCenter = (arr: Position[]) => {
  const x = arr.map((xy: any) => xy[0]);
  const y = arr.map((xy: any) => xy[1]);
  const cx = (Math.min(...x) + Math.max(...x)) / 2;
  const cy = (Math.min(...y) + Math.max(...y)) / 2;

  return [cx, cy];
};
