import { Box, css, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { ReactComponent as FahrradIcon } from '../../assets/icons/Fahrrad.svg';
import { ReactComponent as SoundIcon } from '../../assets/icons/Sound.svg';
import { ReactComponent as TemperaturIcon } from '../../assets/icons/Temperatur.svg';
import { ReactComponent as VerschmutzungIcon } from '../../assets/icons/Verschmutzung.svg';
import { ReactComponent as UserPointsIcon } from '../../assets/icons/user_punkte.svg';
import { ReactComponent as PedestrianIcon } from '../../assets/icons/Pedestrian.svg';
import ScienceCity from '../../assets/icons/Logo_Science_City_Aktiv_Mobil.png';
import { DefaultService } from '../../services/api';
import { KPICard } from './KPICard';
import { AppProps } from '../../types/AppStaticType';

const StyledGrid = styled(Grid)(
  ({ theme }) => css`
    display: flex;
    flexdirection: column;

    ${theme.breakpoints.down('md')} {
      flex-direction: row;
      flex-basis: 50%;
      max-width: 50%;
    }
    ${theme.breakpoints.down('sm')} {
      flex-direction: row;
      flex-basis: 100%;
      max-width: 100%;
    }
  `,
);

export const KPIGrid = ({ isStatic }: AppProps) => {
  const { data, status } = useQuery(['kpis'], () => DefaultService.getKpisKpisGet());

  const kpis = data
    ? [
        {
          key: 'ScienceCity',
          icon: <img src={ScienceCity} height="120px" alt="Science City Aktiv Mobil Logo" />,
        },
        {
          key: 'Bicycle traffic',
          header: 'Bicycle traffic',
          icon: <FahrradIcon aria-hidden width="40px" height="40px" filter="invert(0.3)" />,
          subheader: 'Number of bicycle rides at the measuring station for the previous day.',
          text: data.mobility.cyclists.text,
          value: data.mobility.cyclists.value,
        },
        {
          key: 'Pedestrian traffic',
          header: 'Pedestrian traffic',
          icon: <PedestrianIcon aria-hidden width="40px" height="40px" filter="invert(0.3)" />,
          subheader: 'Number of pedestrians at the measuring station for the previous day.',
          text: data.mobility.pedestrians.text,
          value: data.mobility.pedestrians.value,
        },
        {
          key: 'Points',
          header: 'Points',
          icon: <UserPointsIcon aria-hidden width="40px" height="40px" filter="invert(0.3)" />,
          subheader: 'Number of points collected by participants so far.',
          value: (
            <Box sx={{ display: 'flex' }}>
              {data.points}
              <Typography align="right" variant="subtitle1" sx={{ paddingLeft: 1 }}>
                of 25.000
              </Typography>
            </Box>
          ),
          // value: data.mobility.publicTransport.value,
        },
        {
          key: 'Air quality (fine dust)',
          header: 'Air quality (fine dust)',
          icon: <VerschmutzungIcon aria-hidden width="40px" height="40px" filter="invert(0.3)" />,
          subheader: 'Daily fine dust (PM10) average value in μg/m³ for the previous day.',
          text: data.pollution.air.text,
          value: data.pollution.air.value,
        },
        {
          key: 'Noise',
          header: 'Noise',
          icon: <SoundIcon aria-hidden width="40px" height="40px" filter="invert(0.3)" />,
          subheader: 'Current noise level in dB, measured at the sensor box.',
          text: data.pollution.noise.text,
          value: data.pollution.noise.value,
        },
        {
          key: 'Temperature',
          header: 'Temperature',
          icon: <TemperaturIcon aria-hidden width="40px" height="40px" filter="invert(0.3)" />,
          subheader: 'Current air temperature in °C, measured at the sensor box',
          value: data.weather,
          halfWidth: true,
        },
        {
          key: 'Weather',
          halfWidth: true,
          weatherdata: true,
        },
      ]
    : [];

  return (
    <>
      {!data && status === 'loading' && 'Loading...'}
      {!data && status === 'error' && 'Error'}

      {data && (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} justifyContent="stretch">
          {kpis.map(({ key, header, icon, subheader, text, value, halfWidth, weatherdata }) => (
            <StyledGrid key={key} item xs={halfWidth ? 6 : 4}>
              <KPICard
                header={header}
                icon={icon}
                subheader={subheader}
                text={text}
                value={value}
                weatherdata={weatherdata}
                isStatic={isStatic}
              />
            </StyledGrid>
          ))}
        </Grid>
      )}
    </>
  );
};
