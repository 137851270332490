import * as React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Box, css, IconButton, List, ListItem, ListItemText, styled } from '@mui/material';
import SourceIcon from '@mui/icons-material/Source';

interface Data {
  name: string;
  source: string;
  year: string;
}

const dataList: Data[] = [
  {
    name: 'Copernicus Atmosphere Monitoring Service information',
    source: 'European Union, European Centre for Medium-Range Weather Forecasts (ECMWF)',
    year: '2023',
  },
  {
    name: 'Radverkehrszählungen Stadt Salzburg',
    source: 'GPV - Günther Pichler GmbH; Stadt Salzburg',
    year: '2023',
  },
  {
    name: 'GIP street network with assessed bikeability and walkability',
    source:
      'ASFINAG; ITS Vienna Region; Land Burgenland; Land Kärnten; Land Niederösterreich; Land Oberösterreich; Land Salzburg; Land Steiermark; Land Tirol; Land Vorarlberg; Land Wien; ÖBB Infrastruktur AG; Department of Geoinformatics - University of Salzburg',
    year: '2022',
  },
  {
    name: 'Baustellen in der Stadt Salzburg',
    source: 'Magistrat der Stadt Salzburg 06/00 Baudirektion',
    year: '2023',
  },
  {
    name: 'Fahrplandaten Salzburger Verkehrsverbund (GTFS)',
    source: 'Mobilitätsverbünde Österreich OG - https://data.mobilitaetsverbuende.at',
    year: '2023',
  },
  {
    name: 'Verkehrsinformation',
    source: 'ÖSTERREICHISCHER RUNDFUNK, ORF, ORF-Verkehrsredaktion',
    year: '2023',
  },
  {
    name: 'Echtzeit Verkehrsinformation Straße Österreich',
    source:
      'ASFINAG; die Bundesländer; die Städte Wien und Graz; das BMK; das BMI sowie die Unternehmen Salzburg Research GmbH; VOR/ITS Vienna Region; FH Oberösterreich; RISCSoftware GmbH sowie Verkehrsauskunft Österreich VAO GmbH',
    year: '2023',
  },
  {
    name: 'Administrative boundaries',
    source: 'Bundesamt für Eich- und Vermessungswesen',
    year: '2019',
  },
  {
    name: 'Der österreichischen ALERT-C Location-Code Version 3.7',
    source: 'ASFINAG',
    year: '2022',
  },
];

const StyledBox = styled(Box)(
  ({ theme }) => css`
    background-color: ${theme.palette.common.white};
    padding: 16px;
    display: block;
    width: 80%;
    margin: 0 auto;
    top: 7vh;
    position: relative;
    max-height: 85%;
    overflow: auto;
    padding: ${theme.spacing(2)};

    ${theme.breakpoints.up('lg')} {
      width: 60%;
    }
  `,
);

export const ImpressumModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        size="small"
        sx={{ padding: '10px', marginRight: '8px', borderRadius: '4px', color: 'rgba(0, 0, 0, 0.87)' }}
      >
        <SourceIcon inheritViewBox color="action" />
        <Typography noWrap sx={{ flexGrow: 1, fontSize: { mobile: '12px', xs: '0px', sm: '15px' } }}>
          Data sources
        </Typography>
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledBox>
          <Typography variant="h5" sx={{ textAlign: 'center' }}>
            Data sources:
          </Typography>
          <List dense>
            {dataList.map((data) => (
              <ListItem key={data.name}>
                <ListItemText primary={`${data.name} [${data.year}]`} secondary={data.source} />
              </ListItem>
            ))}
          </List>
        </StyledBox>
      </Modal>
    </>
  );
};
