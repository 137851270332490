import { FeatureLayerType } from '../../types/FeatureLayerType';

export const FeatureLayerLegendText = {
  [FeatureLayerType.BIKEABILITY]: {
    title: 'Bikeability',
    tooltip: 'The bikeability as a grid with a resolution of 100x100 meters.',
  },
  [FeatureLayerType.WALKABILITY]: {
    title: 'Walkability',
    tooltip: 'The walkability as a grid with a resolution of 100x100 meters.',
  },
  [FeatureLayerType.VERKEHRSLAGE]: { title: 'Level of Service', tooltip: '' },
};

export const FeatureLayerLegend = {
  [FeatureLayerType.BIKEABILITY]: [
    { value: 0, label: '≥ 0%', color: 'darkred' },
    { value: 0.2, label: '≥ 20%', color: 'red' },
    { value: 0.4, label: '≥ 40%', color: 'yellow' },
    { value: 0.6, label: '≥ 60%', color: '#cbf779' },
    { value: 0.8, label: '≥ 80%', color: 'green' },
    { value: 0.9, label: '≥ 90%', color: 'darkgreen' },
  ],
  [FeatureLayerType.WALKABILITY]: [
    { value: 0, label: '≥ 0%', color: 'darkred' },
    { value: 0.2, label: '≥ 20%', color: 'red' },
    { value: 0.4, label: '≥ 40%', color: 'yellow' },
    { value: 0.6, label: '≥ 60%', color: '#cbf779' },
    { value: 0.8, label: '≥ 80%', color: 'green' },
    { value: 0.9, label: '≥ 90%', color: 'darkgreen' },
  ],
  [FeatureLayerType.VERKEHRSLAGE]: [
    { value: 'congested', label: 'Congestion', color: 'red' },
    { value: 'heavy', label: 'Heavy traffic', color: '#ffb600' },
    { value: 'freeFlow', label: 'Free flow', color: 'green' },
  ],
};
