import React from 'react';
import { Box, styled, css, FormGroup, RadioGroup, IconButton, Paper } from '@mui/material';

import { BackgroundLayerType } from '../../types/BackgroundLayerType';
import { LayerControlRadio } from './LayerControlRadio';
import { ReactComponent as LayersIcon } from '../../assets/icons/layers.svg';
import { FeatureLayerType } from '../../types/FeatureLayerType';
import { LayerControlCheckbox } from './LayerControlCheckbox';
import { useMapStore } from '../../stores/useMapStore';

const StyledIconButton = styled(IconButton)(
  ({ theme }) =>
    css`
      background-color: ${theme.palette.common.white};
      box-shadow: ${theme.shadows[1]};
    `,
);

const Divider = styled(Box)(
  ({ theme }) => css`
    width: 100%;
    height: 2px;
    background-color: ${theme.palette.secondary.light};
    margin: ${theme.spacing(2)} 0;
  `,
);
const StyledPaper = styled(Paper)(
  ({ theme }) =>
    css`
      display: none;
      padding: ${theme.spacing(2)};
    `,
);

const Root = styled(Box)(
  ({ theme }) =>
    css`
      z-index: 2;
      position: absolute;
      top: ${theme.spacing(1)};
      right: ${theme.spacing(6)};

      &:hover {
        .layers-control-button {
          display: none;
        }

        .layers-control-paper {
          display: block;
        }
      }
    `,
);

export const LayersControl = () => {
  const backgroundLayer = useMapStore((state) => state.backgroundLayer);
  const layers = useMapStore((state) => state.layers);
  const { setBackgroundLayer, setLayers } = useMapStore((state) => state.actions);

  // console.log('LayersControl');
  // console.log(layers);

  if (layers) {
    return (
      <Root>
        <StyledIconButton className="layers-control-button">
          <LayersIcon />
        </StyledIconButton>

        <StyledPaper className="layers-control-paper">
          <FormGroup>
            <RadioGroup>
              {Object.values(BackgroundLayerType).map((layer) => (
                <LayerControlRadio
                  key={layer}
                  value={layer}
                  checked={layer === backgroundLayer}
                  onChange={(checked) => {
                    if (checked) {
                      setBackgroundLayer(layer);
                    }
                  }}
                  i18nKey="BackgroundLayerType"
                />
              ))}
            </RadioGroup>
          </FormGroup>

          <Divider />

          <FormGroup>
            {Object.values(FeatureLayerType).map((layer) => (
              <LayerControlCheckbox
                key={layer}
                value={layer}
                checked={layers.features[layer]}
                onChange={(checked) => {
                  setLayers({ features: { ...layers.features, [layer]: checked } });
                }}
                i18nKey="FeatureLayerType"
              />
            ))}
          </FormGroup>
        </StyledPaper>
      </Root>
    );
  }

  return null;
};
