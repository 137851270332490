import { Box, css, IconButton, Modal, styled } from '@mui/material';
import React, { useEffect } from 'react';
import Plot from 'react-plotly.js';
import TimelineIcon from '@mui/icons-material/Timeline';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import * as localeDictionary from 'plotly.js/lib/locales/de';

import { PlottingVariableControl } from '../controls/PlottingVariableControl';
import { usePlotStore } from '../../stores/usePlotStore';
import { PlottingFeatureCollection } from './types/PlottingFeature';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    background-color: ${theme.palette.common.white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    boxshadow: 24;
    padding: ${theme.spacing(2)};
  `,
);

export const WeatherPopup = ({ features }: PlottingFeatureCollection) => {
  const selectedValue = usePlotStore((state) => state.plottingVariable);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { t } = useTranslation();
  const yaxistitle = t(`PlottingVariables.${selectedValue}`);

  const [plotWidth, setPlotWidth] = React.useState(window.innerWidth / 1.3);
  const [plotHeight, setPlotHeight] = React.useState(window.innerHeight / 2);
  useEffect(() => {
    function handleResize() {
      setPlotWidth(window.innerWidth / 1.3);
      setPlotHeight(window.innerHeight / 2);
    }
    window.addEventListener('resize', handleResize);
  });

  return (
    <>
      <IconButton onClick={handleOpen} size="small" sx={{ padding: '10px', borderRadius: '4px' }}>
        <TimelineIcon />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledBox>
          <PlottingVariableControl />
          <Plot
            data={[
              {
                x: [...features].map((feat) => new Date(feat.properties.date_time).getTime()),
                y: [...features].map((feat) => feat.properties[selectedValue]),
                mode: 'lines',
              },
            ]}
            config={{
              responsive: true,
              scrollZoom: true,
              displayModeBar: false,
              locale: 'de-DE',
              locales: { 'de-DE': localeDictionary },
            }}
            layout={{
              width: plotWidth,
              height: plotHeight,
              showlegend: false,
              margin: { l: 60, r: 20, b: 45, t: 20 },
              xaxis: {
                type: 'date',
              },
              yaxis: {
                title: yaxistitle,
              },
              shapes: [
                {
                  type: 'line',
                  x0: new Date().getTime(),
                  y0: 0,
                  x1: new Date().getTime(),
                  yref: 'paper',
                  y1: 1,
                  line: {
                    color: 'grey',
                    dash: 'dot',
                  },
                },
              ],
            }}
          />
        </StyledBox>
      </Modal>
    </>
  );
};
