import React, { useEffect, useState } from 'react';

import { StyledTableCell } from './StyledTableCell';

type DepartureDiffProps = {
  departuretime: string;
};

export const DepartureDiff = ({ departuretime }: DepartureDiffProps) => {
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 10000);

    return () => clearInterval(intervalId);
  });

  return (
    <StyledTableCell align="center">
      {Math.ceil((new Date(departuretime).getTime() - currentTime) / 1000 / 60)}
    </StyledTableCell>
  );
};
