import { useMap } from 'react-map-gl';

type MapImagesProps = {
  images: { name: string; url: string; width?: number; height?: number }[];
};

export const MapImages = ({ images }: MapImagesProps) => {
  const { current: map } = useMap();

  if (map) {
    images.forEach(({ name, url, width, height }) => {
      if (!map.hasImage(name)) {
        const extension = url.split('.').pop();
        if (extension === 'svg') {
          const img = new Image(width, height);
          img.onload = () => !map.hasImage(name) && map.addImage(name, img);
          img.src = url;
        } else {
          map.loadImage(url, (error, image) => {
            if (error) throw error;
            if (!map.hasImage(name)) {
              map.addImage(name, image as ImageBitmap);
            }
          });
        }
      }
    });
  }

  return null;
};
