import { Box, css, styled } from '@mui/material';
import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { CancelablePromise, DefaultService } from '../../services/api';
import { WeatherTable } from './WeatherTable';
import { WeatherPopup } from './WeatherPopup';
import { PlottingFeatureCollection } from './types/PlottingFeature';
import { AppProps } from '../../types/AppStaticType';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    background-color: ${theme.palette.common.white};
    padding: ${theme.spacing(1)} !important;
    display: flex;
  `,
);

export const WeatherBox = ({ isStatic }: AppProps) => {
  const { data, status } = useQuery(
    ['weather'],
    () => DefaultService.getWeatherWeatherGet() as CancelablePromise<PlottingFeatureCollection>,
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <>
      {!data && status === 'loading' && 'Loading...'}
      {!data && status === 'error' && 'Error'}

      {data && (
        <StyledBox>
          <WeatherTable features={data.features} type="FeatureCollection" />
          {!isStatic && (
            <Box sx={{ margin: 'auto' }}>
              <WeatherPopup features={data.features} type="FeatureCollection" />
            </Box>
          )}
        </StyledBox>
      )}
    </>
  );
};
