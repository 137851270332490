import {
  Box,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import { CancelablePromise, DefaultService } from '../services/api';
import { useMapStore } from '../stores/useMapStore';
import { DeparturesFeatureCollection } from '../types/DepartureFeature';
import { StyledTableCell } from './StyledTableCell';
import { DepartureDiff } from './DepartureDiff';

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.6)',
  fontWeight: 800,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const Monitor = () => {
  const userLocation = useMapStore((state) => state.userLocation);
  const setUserLocation = useMapStore((state) => state.actions.setUserLocation);

  const { data, status } = useQuery(
    ['departures'],
    () =>
      DefaultService.getDeparturesDeparturesGet({
        long: userLocation?.long,
        lat: userLocation?.lat,
      }) as CancelablePromise<DeparturesFeatureCollection>,
    {
      refetchInterval: 10000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      cacheTime: 10000,
    },
  );

  const { setHighlightMonitoringRoute } = useMapStore((state) => state.actions);
  const { setZoomMonitoringRoute } = useMapStore((state) => state.actions);
  const removeHighlight = () => setHighlightMonitoringRoute(undefined);

  const handleClick = () => {
    setUserLocation({ long: 13.0403178113, lat: 47.82272 });
  };

  return (
    <Box>
      {!data && status === 'loading' && 'Loading...'}
      {!data && status === 'error' && 'Error'}

      {data && (
        <TableContainer onMouseLeave={removeHighlight}>
          <Table aria-label="simple table" stickyHeader>
            <TableBody>
              {!data || !data.features ? (
                <StyledTableRow key="desc">
                  <StyledTableHeaderCell
                    align="left"
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 45px',
                      alignItems: 'center',
                    }}
                  >
                    No public transport trips found nearby
                    <Tooltip title="Use Z-Gis location">
                      <IconButton aria-label="delete" onClick={handleClick}>
                        <RestartAltIcon />
                      </IconButton>
                    </Tooltip>
                  </StyledTableHeaderCell>
                </StyledTableRow>
              ) : (
                <>
                  <StyledTableRow key="desc">
                    <StyledTableHeaderCell
                      align="left"
                      sx={{ width: '150px', display: { xs: 'none', mobile: 'table-cell' } }}
                    >
                      Departure
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell align="center" sx={{ width: '85px' }}>
                      in min
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell align="center" sx={{ width: '80px' }}>
                      Line
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                      Stop
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell align="right">Direction</StyledTableHeaderCell>
                  </StyledTableRow>
                  {(data.features || []).map((feature) => (
                    <StyledTableRow
                      key={feature.id}
                      hover
                      onMouseOver={() => {
                        setHighlightMonitoringRoute(feature.properties.route_end_geom);
                      }}
                      onClick={() => {
                        setZoomMonitoringRoute(feature.properties.route_end_geom);
                      }}
                    >
                      <StyledTableCell align="left" sx={{ display: { xs: 'none', mobile: 'table-cell' } }}>
                        {new Date(feature.properties.departure_datetime).toLocaleString('de-DE', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          hour12: false,
                          minute: '2-digit',
                          second: '2-digit',
                        })}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                    {Math.ceil((new Date(feature.properties.departure_datetime).getTime() - currentTime) / 1000 / 60)}
                  </StyledTableCell> */}
                      <DepartureDiff departuretime={feature.properties.departure_datetime} />
                      <StyledTableCell align="center">{feature.properties.route_short_name}</StyledTableCell>
                      <StyledTableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                        {feature.properties.stop_name}
                      </StyledTableCell>
                      {/* <StyledTableCell align="left">{feature.properties.route_long_name}</StyledTableCell> */}
                      <StyledTableCell align="right">{feature.properties.direction}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};
