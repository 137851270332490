import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

type LayerControlCheckboxProps = {
  value: string;
  checked: boolean;
  onChange: (newChecked: boolean) => void;
  i18nKey: string;
};
export const LayerControlCheckbox = ({ value, checked, onChange, i18nKey }: LayerControlCheckboxProps) => {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      sx={{ fontSize: '0.8rem' }}
      label={t(`${i18nKey}.${value}`)}
      control={<Checkbox checked={checked} onChange={(event, newChecked) => onChange(newChecked)} size="small" />}
    />
  );
};
