import { Card, CardHeader, Typography } from '@mui/material';
import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { ReactComponent as NudgeIcon } from '../assets/icons/Nudge.svg';
import { DefaultService } from '../services/api';

export const Nudges = () => {
  const { data, status } = useQuery({
    queryKey: ['kpis'],
    queryFn: () => DefaultService.getKpisKpisGet(),
    refetchInterval: 30000,
  });

  return (
    <>
      {!data && status === 'loading' && 'Loading...'}
      {!data && status === 'error' && 'Error'}

      {data && (
        <Card sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <CardHeader
            sx={{ padding: '12px' }}
            avatar={
              <NudgeIcon aria-hidden width="40px" height="40px" filter="invert(0.5) drop-shadow(2px 4px 6px yellow)" />
            }
            subheader={
              <>
                <Typography sx={{ color: 'black' }} variant="h5">
                  {data.nudge.title}
                </Typography>
                <Typography sx={{ color: 'black' }} variant="subtitle1">
                  {data.nudge.text}
                </Typography>
              </>
            }
          />
        </Card>
      )}
    </>
  );
};
