import { create } from 'zustand';

import { MapState } from '../types/MapState';
import { BackgroundLayerType } from '../types/BackgroundLayerType';
import { FeatureLayerType } from '../types/FeatureLayerType';

export const useMapStore = create<MapState>()((set) => ({
  backgroundLayer: BackgroundLayerType.SATELLITE_MAP,

  layers: {
    features: {
      [FeatureLayerType.TRAFFIC_REPORT]: true,
      [FeatureLayerType.BIKEABILITY]: true,
      [FeatureLayerType.WALKABILITY]: false,
      [FeatureLayerType.VERKEHRSLAGE]: true,
    },
  },

  highlightMonitoringRoute: undefined,
  zoomMonitoringRoute: undefined,
  userLocation: { long: 13.040317811340785, lat: 47.822729816071465 },

  actions: {
    setLayers: (newMapLayers) =>
      set(({ layers }) => ({
        layers: {
          ...layers,
          ...newMapLayers,
          features: {
            ...layers.features,
            ...newMapLayers.features,
          },
        },
      })),
    setBackgroundLayer: (backgroundLayer) => set({ backgroundLayer }),
    setHighlightMonitoringRoute: (highlightMonitoringRoute) => set({ highlightMonitoringRoute }),
    setZoomMonitoringRoute: (zoomMonitoringRoute) => set({ zoomMonitoringRoute }),
    setUserLocation: (userLocation) => set({ userLocation }),
  },
}));
