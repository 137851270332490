import React from 'react';
import { Box, Stack, Typography, styled, css, Tooltip } from '@mui/material';

import { transientOptions } from '../services/transient-options';

const StyledStack = styled(Stack)(
  ({ theme }) =>
    css`
      padding: ${theme.spacing(1.5)};
      text-align: center;
      opacity: 0.9;
    `,
);

const StyledBox = styled(
  Box,
  transientOptions,
)<{ $color: string }>(
  ({ theme, $color }) =>
    css`
      width: ${theme.spacing(3)};
      height: ${theme.spacing(1)};
      background-color: ${$color};
      opacity: 1;
      display: flex;
      align-items: center;
    `,
);

type LegendProps = {
  title: string;
  titleTooltip?: string;
  legend: { value?: string | number; label?: string | number; color: string; text?: string }[];
};

export const LegendControl = ({ title, titleTooltip, legend }: LegendProps) => (
  <StyledStack>
    {titleTooltip ? (
      <Tooltip title={titleTooltip} placement="top">
        <Typography fontWeight="bold">{title}</Typography>
      </Tooltip>
    ) : (
      <Typography fontWeight="bold">{title}</Typography>
    )}
    {legend.map(({ value, label, color, text }) => (
      <Stack key={label || value} direction="row" spacing={2} alignItems="center">
        <StyledBox $color={color} />
        <Tooltip title={text} placement="top">
          <Typography fontSize="0.8rem">{label || value}</Typography>
        </Tooltip>
      </Stack>
    ))}
  </StyledStack>
);
