import { useMapStore } from '../../../stores/useMapStore';
import { FeatureLayerType } from '../../../types/FeatureLayerType';

export const useBeforeIdLabels = () => {
  const layers = useMapStore((state) => state.layers);

  let beforeIdLabels;

  switch (layers.features.TRAFFIC_REPORT) {
    case true:
      [beforeIdLabels] = [FeatureLayerType.TRAFFIC_REPORT];
      break;
    case false:
      beforeIdLabels = undefined;
      break;
    default:
      beforeIdLabels = undefined;
  }

  return { beforeIdLabels };
};
