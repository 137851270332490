import { RadioGroup } from '@mui/material';
import React from 'react';

import { PlottingVariables } from '../../types/PlottingVariables';
import { LayerControlRadio } from './LayerControlRadio';
import { usePlotStore } from '../../stores/usePlotStore';

export const PlottingVariableControl = () => {
  const selectedValue = usePlotStore((state) => state.plottingVariable);
  const setSelectedValue = usePlotStore((state) => state.actions.setPlottingVariable);

  return (
    <RadioGroup row sx={{ display: 'block', textAlign: 'center' }}>
      {Object.values(PlottingVariables).map((variable) => (
        <LayerControlRadio
          key={variable}
          value={variable}
          checked={variable === selectedValue}
          onChange={(checked) => {
            if (checked) {
              setSelectedValue(variable);
            }
          }}
          i18nKey="PlottingVariables"
        />
      ))}
    </RadioGroup>
  );
};
