import { LinePaint } from 'mapbox-gl';
import React from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl';
import { useQuery } from '@tanstack/react-query';

import { DefaultService } from '../../services/api';
import { FeatureLayerType } from '../../types/FeatureLayerType';
import { useBeforeIdLabels } from './hooks/useBeforeIds';
import { WhiteLineBorderLayer } from './WhiteBorderlineLayer';
// import { WhiteLineBorderLayer } from './WhiteBorderlineLayer';

const colorTrafficPaintbyType: LinePaint = {
  'line-blur': 1,

  'line-color': [
    'match',
    ['get', 'traffic_status'],
    'congested',
    'red',
    'heavy',
    '#ffb600',
    'freeFlow',
    'green',
    'gray',
  ],
  // 'line-width': ['match', ['get', 'traffic_status'], 'congested', 10, 'heavy', 8, 'freeFlow', 1.5, 1],
  'line-width': ['interpolate', ['exponential', 0.3], ['zoom'], 10, 1, 14, 3.5, 18, 7],
};
// const colorTrafficPaintbyTraveltime: LinePaint = {
//   'line-width': 3,
//   'line-color': [
//     'interpolate',
//     ['linear'],
//     ['get', 'astravel_time'],
//     0,
//     'darkgreen',
//     10,
//     'green',
//     30,
//     '#cbf779',
//     60,
//     'yellow',
//     80,
//     'red',
//     100,
//     'darkred',
//   ],
// };
// const colorTrafficPaintbyVehiclespeed: LinePaint = {
//   'line-width': 3,
//   'line-color': [
//     'interpolate',
//     ['linear'],
//     ['get', 'asaverage_vehicle_speed'],
//     0,
//     'darkred',
//     10,
//     'red',
//     30,
//     'yellow',
//     60,
//     '#cbf779',
//     80,
//     'green',
//     100,
//     'darkgreen',
//   ],
// };

const verkehrslageLayerProps: LayerProps = {
  id: FeatureLayerType.VERKEHRSLAGE,
  type: 'line',
  // paint: colorTrafficPaintbyVehiclespeed,
  // paint: colorTrafficPaintbyTraveltime,
  paint: colorTrafficPaintbyType,
  minzoom: 0,
  maxzoom: 22,
};

export const VerkehrslageLayer = () => {
  const { data, status } = useQuery(['traffic_flow_state'], () =>
    DefaultService.getTrafficFlowStateTrafficFlowStateGet(),
  );
  const { beforeIdLabels } = useBeforeIdLabels();

  return (
    <>
      {!data && status === 'loading' && 'Loading...'}
      {!data && status === 'error' && 'Error'}

      {data && (
        <Source type="geojson" id={FeatureLayerType.VERKEHRSLAGE} data={data}>
          <WhiteLineBorderLayer />
          <Layer
            beforeId={beforeIdLabels}
            layout={{
              'line-cap': 'round',
              'line-join': 'round',
              'line-miter-limit': 2,
            }}
            {...verkehrslageLayerProps}
          />
        </Source>
      )}
    </>
  );
};
