import { create } from 'zustand';

import { PlottingVariables } from '../types/PlottingVariables';
import { PlotState } from '../components/kpi/types/PlotState';

export const usePlotStore = create<PlotState>()((set) => ({
  plottingVariable: PlottingVariables.TEMPERATUR,

  actions: {
    setPlottingVariable: (plottingVariable) => set({ plottingVariable }),
  },
}));
