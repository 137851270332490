import React from 'react';

import { FeatureLayerType } from '../../types/FeatureLayerType';
import { BikeabilityLayer } from './Bikeability';
import { WalkabilityLayer } from './Walkability';
import { useMapStore } from '../../stores/useMapStore';
import { VerkehrslageLayer } from './VerkehrslageLayer';
import { TrafficReportLayer } from './TrafficReportLayer';

export const FeatureLayers = () => {
  const mapLayers = useMapStore((state) => state.layers);

  return (
    <>
      {Object.values(FeatureLayerType).map(
        (layer) =>
          mapLayers.features[layer] &&
          {
            [FeatureLayerType.BIKEABILITY]: <BikeabilityLayer key={layer} />,
            [FeatureLayerType.WALKABILITY]: <WalkabilityLayer key={layer} />,
            [FeatureLayerType.VERKEHRSLAGE]: <VerkehrslageLayer key={layer} />,
            [FeatureLayerType.TRAFFIC_REPORT]: <TrafficReportLayer key={layer} />,
          }[layer],
      )}
    </>
  );
};
