import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'maplibre-gl/dist/maplibre-gl.css';

import './setup/api';
import './setup/i18n';
import { App } from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App isStatic={false} />} />
        <Route path="/static" element={<App isStatic />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);
